/* tslint:disable */
/* eslint-disable */
/**
 * Celeste Speedrun Library
 * Digital Library covering all things Celeste speedrunning.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Checkpoint } from './Checkpoint';
import {
    CheckpointFromJSON,
    CheckpointFromJSONTyped,
    CheckpointToJSON,
} from './Checkpoint';

/**
 * A single room
 * @export
 * @interface Room
 */
export interface Room {
    /**
     * Unique identifier for the room
     * @type {string}
     * @memberof Room
     */
    token: string;
    /**
     * Debug code of the room
     * @type {string}
     * @memberof Room
     */
    code: string;
    /**
     * The rooms connected to this one
     * @type {Array<Room>}
     * @memberof Room
     */
    connected?: Array<Room>;
    /**
     * Image of a portion of the room that fits on a single screen
     * @type {string}
     * @memberof Room
     */
    imagePreview?: string;
    /**
     * Image of the entire room
     * @type {string}
     * @memberof Room
     */
    imageFull?: string;
    /**
     * 
     * @type {Checkpoint}
     * @memberof Room
     */
    checkpoint?: Checkpoint;
}

/**
 * Check if a given object implements the Room interface.
 */
export function instanceOfRoom(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "code" in value;

    return isInstance;
}

export function RoomFromJSON(json: any): Room {
    return RoomFromJSONTyped(json, false);
}

export function RoomFromJSONTyped(json: any, ignoreDiscriminator: boolean): Room {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'code': json['code'],
        'connected': !exists(json, 'connected') ? undefined : ((json['connected'] as Array<any>).map(RoomFromJSON)),
        'imagePreview': !exists(json, 'image_preview') ? undefined : json['image_preview'],
        'imageFull': !exists(json, 'image_full') ? undefined : json['image_full'],
        'checkpoint': !exists(json, 'checkpoint') ? undefined : CheckpointFromJSON(json['checkpoint']),
    };
}

export function RoomToJSON(value?: Room | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'code': value.code,
        'connected': value.connected === undefined ? undefined : ((value.connected as Array<any>).map(RoomToJSON)),
        'image_preview': value.imagePreview,
        'image_full': value.imageFull,
        'checkpoint': CheckpointToJSON(value.checkpoint),
    };
}

