/* tslint:disable */
/* eslint-disable */
/**
 * Celeste Speedrun Library
 * Digital Library covering all things Celeste speedrunning.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A chapter
 * @export
 * @interface Chapter
 */
export interface Chapter {
    /**
     * Unique identifier for the chapter
     * @type {string}
     * @memberof Chapter
     */
    token: string;
    /**
     * Full chapter name
     * @type {string}
     * @memberof Chapter
     */
    name: string;
    /**
     * Name of the chapter group
     * @type {string}
     * @memberof Chapter
     */
    group?: string;
    /**
     * Side of the Chapter
     * @type {string}
     * @memberof Chapter
     */
    side?: ChapterSideEnum;
    /**
     * Image representing the chapter
     * @type {string}
     * @memberof Chapter
     */
    image?: string;
}


/**
 * @export
 */
export const ChapterSideEnum = {
    A: 'A',
    B: 'B',
    C: 'C'
} as const;
export type ChapterSideEnum = typeof ChapterSideEnum[keyof typeof ChapterSideEnum];


/**
 * Check if a given object implements the Chapter interface.
 */
export function instanceOfChapter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function ChapterFromJSON(json: any): Chapter {
    return ChapterFromJSONTyped(json, false);
}

export function ChapterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Chapter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'name': json['name'],
        'group': !exists(json, 'group') ? undefined : json['group'],
        'side': !exists(json, 'side') ? undefined : json['side'],
        'image': !exists(json, 'image') ? undefined : json['image'],
    };
}

export function ChapterToJSON(value?: Chapter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'name': value.name,
        'group': value.group,
        'side': value.side,
        'image': value.image,
    };
}

