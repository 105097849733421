/* tslint:disable */
/* eslint-disable */
/**
 * Celeste Speedrun Library
 * Digital Library covering all things Celeste speedrunning.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Media } from './Media';
import {
    MediaFromJSON,
    MediaFromJSONTyped,
    MediaToJSON,
} from './Media';
import type { Source } from './Source';
import {
    SourceFromJSON,
    SourceFromJSONTyped,
    SourceToJSON,
} from './Source';

/**
 * A strategy for some portion of a speedrun
 * @export
 * @interface Strat
 */
export interface Strat {
    /**
     * Unique identifier for the strat
     * @type {string}
     * @memberof Strat
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof Strat
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Strat
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Strat
     */
    notes?: string;
    /**
     * 
     * @type {string}
     * @memberof Strat
     */
    start?: string;
    /**
     * 
     * @type {string}
     * @memberof Strat
     */
    end?: string;
    /**
     * 
     * @type {Array<Media>}
     * @memberof Strat
     */
    media?: Array<Media>;
    /**
     * 
     * @type {Array<Source>}
     * @memberof Strat
     */
    sources?: Array<Source>;
}

/**
 * Check if a given object implements the Strat interface.
 */
export function instanceOfStrat(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token" in value;

    return isInstance;
}

export function StratFromJSON(json: any): Strat {
    return StratFromJSONTyped(json, false);
}

export function StratFromJSONTyped(json: any, ignoreDiscriminator: boolean): Strat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token': json['token'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'end': !exists(json, 'end') ? undefined : json['end'],
        'media': !exists(json, 'media') ? undefined : ((json['media'] as Array<any>).map(MediaFromJSON)),
        'sources': !exists(json, 'sources') ? undefined : ((json['sources'] as Array<any>).map(SourceFromJSON)),
    };
}

export function StratToJSON(value?: Strat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token': value.token,
        'name': value.name,
        'description': value.description,
        'notes': value.notes,
        'start': value.start,
        'end': value.end,
        'media': value.media === undefined ? undefined : ((value.media as Array<any>).map(MediaToJSON)),
        'sources': value.sources === undefined ? undefined : ((value.sources as Array<any>).map(SourceToJSON)),
    };
}

