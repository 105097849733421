/* tslint:disable */
/* eslint-disable */
/**
 * Celeste Speedrun Library
 * Digital Library covering all things Celeste speedrunning.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Category,
  Chapter,
  Checkpoint,
  GetApiStatus200Response,
  Room,
  Strat,
} from '../models';
import {
    CategoryFromJSON,
    CategoryToJSON,
    ChapterFromJSON,
    ChapterToJSON,
    CheckpointFromJSON,
    CheckpointToJSON,
    GetApiStatus200ResponseFromJSON,
    GetApiStatus200ResponseToJSON,
    RoomFromJSON,
    RoomToJSON,
    StratFromJSON,
    StratToJSON,
} from '../models';

export interface GetCheckpointByRoomRequest {
    room: string;
}

export interface GetCheckpointsRequest {
    chapter: string;
}

export interface GetRoomsRequest {
    checkpoint: string;
    category?: string;
}

export interface GetStratsRequest {
    room: string;
    category?: string;
}

export interface ShowChapterRequest {
    chapter: string;
}

export interface ShowCheckpointRequest {
    checkpoint: string;
}

export interface ShowRoomRequest {
    room: string;
    category?: string;
}

export interface ShowStratRequest {
    strat: string;
}

/**
 * DefaultApi - interface
 * 
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
    /**
     * Check that the API is up and running
     * @summary Healthcheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getApiStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetApiStatus200Response>>;

    /**
     * Check that the API is up and running
     * Healthcheck
     */
    getApiStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetApiStatus200Response>;

    /**
     * 
     * @summary List speedrun categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Category>>>;

    /**
     * List speedrun categories
     */
    getCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Category>>;

    /**
     * List all chapters, including B-Sides and C-Sides, in vanilla Celeste
     * @summary List all chapters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getChaptersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Chapter>>>;

    /**
     * List all chapters, including B-Sides and C-Sides, in vanilla Celeste
     * List all chapters
     */
    getChapters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Chapter>>;

    /**
     * 
     * @summary Get the checkpoint containing the specified room
     * @param {string} room Token of the room to get checkpoint for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCheckpointByRoomRaw(requestParameters: GetCheckpointByRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Checkpoint>>;

    /**
     * Get the checkpoint containing the specified room
     */
    getCheckpointByRoom(requestParameters: GetCheckpointByRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Checkpoint>;

    /**
     * 
     * @summary List all checkpoints for the specified chapter
     * @param {string} chapter Token of the chapter to list checkpoints for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getCheckpointsRaw(requestParameters: GetCheckpointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Checkpoint>>>;

    /**
     * List all checkpoints for the specified chapter
     */
    getCheckpoints(requestParameters: GetCheckpointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Checkpoint>>;

    /**
     * 
     * @summary List all rooms for the specified checkpoint
     * @param {string} checkpoint Token of the checkpoint to list rooms for
     * @param {string} [category] Speedrun category to filter rooms by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getRoomsRaw(requestParameters: GetRoomsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Room>>>;

    /**
     * List all rooms for the specified checkpoint
     */
    getRooms(requestParameters: GetRoomsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Room>>;

    /**
     * 
     * @summary List all known strats for the specified room
     * @param {string} room Token of the room
     * @param {string} [category] Speedrun category to filter strats by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getStratsRaw(requestParameters: GetStratsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Strat>>>;

    /**
     * List all known strats for the specified room
     */
    getStrats(requestParameters: GetStratsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Strat>>;

    /**
     * 
     * @summary Show the specified chapter
     * @param {string} chapter Token of the chapter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    showChapterRaw(requestParameters: ShowChapterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Chapter>>;

    /**
     * Show the specified chapter
     */
    showChapter(requestParameters: ShowChapterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Chapter>;

    /**
     * 
     * @summary Show the specified checkpoint
     * @param {string} checkpoint Token of the checkpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    showCheckpointRaw(requestParameters: ShowCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Checkpoint>>;

    /**
     * Show the specified checkpoint
     */
    showCheckpoint(requestParameters: ShowCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Checkpoint>;

    /**
     * 
     * @summary Show the specified room
     * @param {string} room Token of the room
     * @param {string} [category] Speedrun category to filter rooms by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    showRoomRaw(requestParameters: ShowRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Room>>;

    /**
     * Show the specified room
     */
    showRoom(requestParameters: ShowRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Room>;

    /**
     * 
     * @summary Show the specified strat
     * @param {string} strat Token of the strat
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    showStratRaw(requestParameters: ShowStratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Strat>>;

    /**
     * Show the specified strat
     */
    showStrat(requestParameters: ShowStratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Strat>;

}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {

    /**
     * Check that the API is up and running
     * Healthcheck
     */
    async getApiStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetApiStatus200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetApiStatus200ResponseFromJSON(jsonValue));
    }

    /**
     * Check that the API is up and running
     * Healthcheck
     */
    async getApiStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetApiStatus200Response> {
        const response = await this.getApiStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * List speedrun categories
     */
    async getCategoriesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Category>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryFromJSON));
    }

    /**
     * List speedrun categories
     */
    async getCategories(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Category>> {
        const response = await this.getCategoriesRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all chapters, including B-Sides and C-Sides, in vanilla Celeste
     * List all chapters
     */
    async getChaptersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Chapter>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/chapters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChapterFromJSON));
    }

    /**
     * List all chapters, including B-Sides and C-Sides, in vanilla Celeste
     * List all chapters
     */
    async getChapters(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Chapter>> {
        const response = await this.getChaptersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the checkpoint containing the specified room
     */
    async getCheckpointByRoomRaw(requestParameters: GetCheckpointByRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Checkpoint>> {
        if (requestParameters.room === null || requestParameters.room === undefined) {
            throw new runtime.RequiredError('room','Required parameter requestParameters.room was null or undefined when calling getCheckpointByRoom.');
        }

        const queryParameters: any = {};

        if (requestParameters.room !== undefined) {
            queryParameters['room'] = requestParameters.room;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/checkpoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointFromJSON(jsonValue));
    }

    /**
     * Get the checkpoint containing the specified room
     */
    async getCheckpointByRoom(requestParameters: GetCheckpointByRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Checkpoint> {
        const response = await this.getCheckpointByRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all checkpoints for the specified chapter
     */
    async getCheckpointsRaw(requestParameters: GetCheckpointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Checkpoint>>> {
        if (requestParameters.chapter === null || requestParameters.chapter === undefined) {
            throw new runtime.RequiredError('chapter','Required parameter requestParameters.chapter was null or undefined when calling getCheckpoints.');
        }

        const queryParameters: any = {};

        if (requestParameters.chapter !== undefined) {
            queryParameters['chapter'] = requestParameters.chapter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/checkpoints`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CheckpointFromJSON));
    }

    /**
     * List all checkpoints for the specified chapter
     */
    async getCheckpoints(requestParameters: GetCheckpointsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Checkpoint>> {
        const response = await this.getCheckpointsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all rooms for the specified checkpoint
     */
    async getRoomsRaw(requestParameters: GetRoomsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Room>>> {
        if (requestParameters.checkpoint === null || requestParameters.checkpoint === undefined) {
            throw new runtime.RequiredError('checkpoint','Required parameter requestParameters.checkpoint was null or undefined when calling getRooms.');
        }

        const queryParameters: any = {};

        if (requestParameters.checkpoint !== undefined) {
            queryParameters['checkpoint'] = requestParameters.checkpoint;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rooms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoomFromJSON));
    }

    /**
     * List all rooms for the specified checkpoint
     */
    async getRooms(requestParameters: GetRoomsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Room>> {
        const response = await this.getRoomsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all known strats for the specified room
     */
    async getStratsRaw(requestParameters: GetStratsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Strat>>> {
        if (requestParameters.room === null || requestParameters.room === undefined) {
            throw new runtime.RequiredError('room','Required parameter requestParameters.room was null or undefined when calling getStrats.');
        }

        const queryParameters: any = {};

        if (requestParameters.room !== undefined) {
            queryParameters['room'] = requestParameters.room;
        }

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/strats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StratFromJSON));
    }

    /**
     * List all known strats for the specified room
     */
    async getStrats(requestParameters: GetStratsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Strat>> {
        const response = await this.getStratsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the specified chapter
     */
    async showChapterRaw(requestParameters: ShowChapterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Chapter>> {
        if (requestParameters.chapter === null || requestParameters.chapter === undefined) {
            throw new runtime.RequiredError('chapter','Required parameter requestParameters.chapter was null or undefined when calling showChapter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/chapters/{chapter}`.replace(`{${"chapter"}}`, encodeURIComponent(String(requestParameters.chapter))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChapterFromJSON(jsonValue));
    }

    /**
     * Show the specified chapter
     */
    async showChapter(requestParameters: ShowChapterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Chapter> {
        const response = await this.showChapterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the specified checkpoint
     */
    async showCheckpointRaw(requestParameters: ShowCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Checkpoint>> {
        if (requestParameters.checkpoint === null || requestParameters.checkpoint === undefined) {
            throw new runtime.RequiredError('checkpoint','Required parameter requestParameters.checkpoint was null or undefined when calling showCheckpoint.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/checkpoints/{checkpoint}`.replace(`{${"checkpoint"}}`, encodeURIComponent(String(requestParameters.checkpoint))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckpointFromJSON(jsonValue));
    }

    /**
     * Show the specified checkpoint
     */
    async showCheckpoint(requestParameters: ShowCheckpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Checkpoint> {
        const response = await this.showCheckpointRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the specified room
     */
    async showRoomRaw(requestParameters: ShowRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Room>> {
        if (requestParameters.room === null || requestParameters.room === undefined) {
            throw new runtime.RequiredError('room','Required parameter requestParameters.room was null or undefined when calling showRoom.');
        }

        const queryParameters: any = {};

        if (requestParameters.category !== undefined) {
            queryParameters['category'] = requestParameters.category;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rooms/{room}`.replace(`{${"room"}}`, encodeURIComponent(String(requestParameters.room))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoomFromJSON(jsonValue));
    }

    /**
     * Show the specified room
     */
    async showRoom(requestParameters: ShowRoomRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Room> {
        const response = await this.showRoomRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the specified strat
     */
    async showStratRaw(requestParameters: ShowStratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Strat>> {
        if (requestParameters.strat === null || requestParameters.strat === undefined) {
            throw new runtime.RequiredError('strat','Required parameter requestParameters.strat was null or undefined when calling showStrat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/strats/{strat}`.replace(`{${"strat"}}`, encodeURIComponent(String(requestParameters.strat))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StratFromJSON(jsonValue));
    }

    /**
     * Show the specified strat
     */
    async showStrat(requestParameters: ShowStratRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Strat> {
        const response = await this.showStratRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
