/* tslint:disable */
/* eslint-disable */
/**
 * Celeste Speedrun Library
 * Digital Library covering all things Celeste speedrunning.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Image or video file
 * @export
 * @interface Media
 */
export interface Media {
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Media
     */
    mimetype: string;
    /**
     * 
     * @type {number}
     * @memberof Media
     */
    framerate?: number;
}

/**
 * Check if a given object implements the Media interface.
 */
export function instanceOfMedia(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "mimetype" in value;

    return isInstance;
}

export function MediaFromJSON(json: any): Media {
    return MediaFromJSONTyped(json, false);
}

export function MediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Media {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'mimetype': json['mimetype'],
        'framerate': !exists(json, 'framerate') ? undefined : json['framerate'],
    };
}

export function MediaToJSON(value?: Media | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'mimetype': value.mimetype,
        'framerate': value.framerate,
    };
}

